<template>
  <Layout>
    <div class="row votosPorCandidaturaMovil" v-if="cargaTerminada">
      <div class="col-12 col-md-10 mx-auto">
        <router-link
          :to="`/${eleccion}/DET/VPP/${distrito}/${listaSecciones[0].seccion_id}`"
          class="btn btn-secondary btn-link text-center font-weight-bold mb-3 mx-auto d-block text-white w-75"
        >
          Sección
        </router-link>

        <h4>
          {{ titulo }} -
          <b class="primario">Detalle del Distrito</b> -
          <span class="primario">Votos por Partido Político, Candidatura Independiente y Candidatura Común</span>
        </h4>

        <p>
          <router-link :to="`/${eleccion}/ENT/VPC`" class="estado-volver">
            Baja California Sur
          </router-link> / <b>
            <span v-if="eleccion != 'A'">
              Distrito {{distrito}}. {{distrito_nombre}}
            </span>
            <span v-else>
              Municipio {{ayuntamiento.municipio_id}}. {{ayuntamiento.municipio_descripcion}}
            </span>
          </b>
        </p>

        <p>
          El total de votos mostrado a nivel Entidad representa la suma del voto emitido en territorio Estatal y en el Extranjero.
        </p>

        <p class="mb-5">
          Por representación, los decimales de los porcentajes muestran sólo cuatro digitos. No obstante, al considera todos los decimales, suman 100%.
        </p>

        <div class="totalVotos mb-5">
          <h5 class="text-center"><b>Total de votos</b></h5>

          <h1 class="total mb-0">
            {{ resumenDistrito.total_votos_general_numero }}
          </h1>
          <p class="porcentaje mb-3">
            {{ resumenDistrito.total_votos_general_porcentaje }}%
          </p>
        </div>

        <div class="votosEnActasContabilizadas">
          <p class="titulo">
            Votos en Actas Contabilizadas
          </p>

          <p>
            Presiona sobre el recuadro blanco y selecciona de una a tres opciones para conocer el detalle de la votación.
          </p>
        </div>

        <div class="row">
          <voto-por-partido v-for="(partido, index) in votosXPartidoXDistrito.datos_candidaturas" 
          :key="index" 
          :partido="partido" 
          :esDetalle="true"
          :esXDistrito="true" />
        </div>

        <div class="resumenDeLaVotacion mb-5">
          <p class="titulo">
            Resumen de la votación
          </p>

          <ResumenVotacion texto="Candidaturas no registradas" 
          :numero="resumenDistrito.total_votos_candidatura_noregistrada_numero"
          :porcentaje="resumenDistrito.total_votos_candidatura_noregistrada_porcentaje" />
          <ResumenVotacion texto="Nulos" 
          :numero="resumenDistrito.total_votos_nulos_numero"
          :porcentaje="resumenDistrito.total_votos_nulos_porcentaje" />

          <div class="row">
            <div class="col-12">
              <button class="btn btn-primary float-right" v-b-modal.DetalleEleccionPartidosPoliticosModal
              @click="actualizarDetalleEleccion()">
                Ver detalle
              </button>
            </div>
          </div>
        </div>

        <div class="votosEnActasContabilizadas">
          <p class="titulo">
            Estadística Distrito {{ distrito }}
          </p>
        </div>

        <div class="estadisticaEntidad mb-3">
          <h5 class="font-weight-bold mb-3">
            <u>Actas</u>
          </h5>
          <div class="grilla-estadisticas mb-4">
            <div class="esperadas">
              <ProgresoVertical class="mb-3" :avance="estadisticas.actas_esperadas_porcentaje" :color="colorEsperadas" />
              <p>
                {{ estadisticas.actas_esperadas_numero }} <br />
                {{ estadisticas.actas_esperadas_porcentaje }}% <br />
                Esperadas
              </p>
            </div>
            <div class="capturadas">
              <ProgresoVertical class="mb-3" :avance="estadisticas.actas_capturadas_porcentaje" :color="colorCapturadas" />
              <p>
                {{ estadisticas.actas_capturadas_numero }} <br />
                {{ estadisticas.actas_capturadas_porcentaje}}% <br />
                Capturadas
              </p>
            </div>
            <div class="contabilizadas">
              <ProgresoVertical class="mb-3" :avance="estadisticas.actas_contabilizadas_porcentaje" :color="colorContabilizadas" />
              <p>
                {{ estadisticas.actas_contabilizadas_numero }} <br />
                {{ estadisticas.actas_contabilizadas_porcentaje }}% <br />
                Contabilizadas
              </p>
            </div>
          </div>
        </div>

        <div class="listaNominal mb-5">
          <h5 class="font-weight-bold mb-3">
            Lista Nominal
          </h5>

          <h1 class="text-center font-weight-bold mb-3">
            {{  estadisticas.valor_lista_nominal_actas_contabilizadas_numero }}
          </h1>
          <p class="text-center small mb-4">
            Lista Nominal de Actas Contabilizadas
          </p>

          <h5 class="font-weight-bold mb-3">
            Participación ciudadana con base en la Lista Nominal de las Actas PREP Contabilizadas
          </h5>

          <p>
            El porcentaje de Participación Ciudadana se obtiene al multiplicar los Votos por 100 y dividir el resultado de esta operación entre el número de personas registrado en la Lista Nominal de Actas Contabilizadas.
          </p>

          <p class="text-center font-weight-bold mb-2">
            Porcentaje
          </p>
          <h1 class="text-center font-weight-bold primario display-4">
            {{  estadisticas.valor_lista_nominal_actas_contabilizadas_porcentaje }}%
          </h1>
          <p class="text-center mb-0">
            Porcentaje calculado <b>sin considerar</b> la votación<br />
            en <b>Casillas Especiales.</b>
          </p>
          <p class="text-center mb-2">
            (Total de votos de las Actas Contabilizadas / Lista Nominal) x 100 = % de Participación Ciudadana.
          </p>
        </div>
      </div>

      <detalle-eleccion :resumen="resumenDistrito" :votos="votosXPartidoXDistrito" :esXDistrito="true" :key="random"/>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/Movil/layouts/Layout'
import VotoPorPartido from '../../componentes/VotoPorPartido.vue'
import ResumenVotacion from '@/components/Movil/componentes/ResumenVotacion'
import DetalleEleccion from '@/components/DetalleEleccionPartidosPoliticos.vue'
import ProgresoVertical from '@/components/Movil/componentes/ProgresoVertical'
import obtenerColoresDeAvatar from '@/utils/obtenerColoresDeAvatar'

export default {
  name: 'DetalleDelDistritoMovil',
  components: {
    Layout,
    VotoPorPartido,
    ResumenVotacion,
    DetalleEleccion,
    ProgresoVertical
  },
  data() {
    return { 
      random:0
    }
  },
  computed: {
    distrito_nombre() {
      return this.$store.state.elementosGenerales.datos_distritos.find(
        d => d.id == this.distrito
      ).descripcion;
    },
    detalle () {
      /**
       * Aqui debemos de llamar al distrito correspondiente acorde a las variables "Entidad" y "Distrito",
       * por lo pronto quedará estático con lo que tenemos en el JSON que nos dio el INE
       */

      return this.$store.state.distritos.uno
    },
    titulo () {
      if (this.$route.params.eleccion === 'G') { return 'Gubernatura' } else if (this.$route.params.eleccion === 'A') { return 'Ayuntamiento' } else { return 'Diputaciones' }
    },
    eleccion () {
      return this.$route.params.eleccion
    },
    distrito () {
      return this.$route.params.distrito
    },
    ayuntamiento () {
      return this.$store.state.elementosGenerales.datos_municipios[this.distrito - 1]
    },
    cargaTerminada () {
      return this.$store.state.cargaTerminada
    },
    colores () {
      return obtenerColoresDeAvatar(this)
    },
    colorEsperadas () {
      if (this.$store.state.esOscuro) {
        return 'fd2a71'
      }
      return 'd2127e'
    },
    colorCapturadas () {
      if (this.$store.state.esOscuro) {
        return 'fe9db0'
      }
      return '79144c'
    },
    colorContabilizadas () {
      if (this.$store.state.esOscuro) {
        return 'fec5cd'
      }
      return 'efb5d3'
    },
    votosXPartidoXDistrito(){
      if(this.eleccion == "G"){
        return this.$store.state.gVotosXPartidosNivelDistrito
                .distritos[this.distrito - 1]
      }else if(this.eleccion == "D"){
        return this.$store.state.dVotosXPartidosNivelDistrito
                .distritos[this.distrito - 1]
      }else if(this.eleccion == "A"){
        return this.$store.state.aVotosXPartidosNivelDistrito
                .municipios[this.distrito - 1]
      }
    },
    resumenDistrito(){
      if(this.eleccion == "G"){
        return this.$store.state.gResumenNivelDistrito.distritos[this.$route.params.distrito-1]
      }else if(this.eleccion == "D"){
        return this.$store.state.dResumenNivelDistrito.distritos[this.$route.params.distrito-1]
      }else if(this.eleccion == "A"){
        return this.$store.state.aResumenNivelDistrito.distritos[this.$route.params.distrito-1]
      }
    },
    estadisticas(){
      if(this.eleccion == "G"){
        return this.$store.state.gEstadisticasNivelDistrito.distritos[this.$route.params.distrito-1]
      }else if(this.eleccion == "D"){
        return this.$store.state.dEstadisticasNivelDistrito.distritos[this.$route.params.distrito-1]
      }else if(this.eleccion == "A"){
        return this.$store.state.aEstadisticasNivelDistrito.municipios[this.$route.params.distrito-1]
      }
    },
    distribucionXPP(){
       if(this.eleccion == "G"){
        return this.$store.state.gDistribucionVotosXPPNivelDistrito.distritos[this.$route.params.distrito-1]
      }else if(this.eleccion == "D"){
        return this.$store.state.dDistribucionVotosXPPNivelDistrito.distritos[this.$route.params.distrito-1]
      }else if(this.eleccion == "A"){
        return this.$store.state.aDistribucionVotosXPPNivelDistrito.municipios[this.$route.params.distrito-1]
      }
    },
    listaSecciones(){
      if(this.eleccion != 'A'){
         return this.$store.state.elementosGenerales.datos_secciones
         .distritos[this.distrito - 1]
         .datos_secciones
      }else{
        return this.$store.state.elementosGenerales.
        datos_secciones_por_municipio[this.distrito - 1]
        .datos_secciones
      }
    }
  },
  methods: {
    actualizarDetalleEleccion() {
        this.$forceUpdate();  
        this.random = Math.random();
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-link {
  border-radius: 15px;
  background-color: #34344e;
  border-color: transparent;
}
</style>
