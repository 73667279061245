<template>
  <div>
    <DetalleDelDistritoVPPMovil v-if="anchoPantalla < 992" />
    <DetalleDelDistritoVPPEscritorio v-else />
  </div>
</template>

<script>
import DetalleDelDistritoVPPMovil from '@/components/Movil/vistas/VotosPorPartidoPolitico/DetalleDelDistrito.vue'
import DetalleDelDistritoVPPEscritorio from '@/components/Escritorio/vistas/VotosPorPartidoPolitico/DetalleDelDistrito.vue'

export default {
  name: 'DetalleDelDistritoVPP',
  components: {
    DetalleDelDistritoVPPMovil,
    DetalleDelDistritoVPPEscritorio
  },
  metaInfo: {
    title: 'IEEBCS - PREP 2021 - Entidad - Votos Por Partidos Políticos y Candidaturas Independientes - Detalles del Distrito'
  },
  computed: {
    anchoPantalla () {
      return this.$store.state.anchoPantalla
    }
  }
}
</script>
