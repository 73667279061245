<template>
  <b-modal
    id="DetalleEleccionPartidosPoliticosModal"
    size="lg"
    title="Detalle"
    header-bg-variant="info"
    hide-footer
    no-close-on-backdrop
    centered
  >
  <div class="container">
      <div class="row">
        <div class="col-md-3 col-12">
          <div class="resumen">
            <p class="font-weight-bold mb-0">Total de votos</p>
            <h1 class="total primario">
              <span>
                {{ resumen.total_votos_general_numero }}
              </span>
            </h1>

            <p class="porcentaje primario">
              <span>
                {{ resumen.total_votos_general_porcentaje }}%
              </span>
            </p>
          </div>
        </div>
        <div class="col-md-9 col-12">
          <div v-if="seleccionados" class="row mb-12">
            <div
              class="detalle-card col-12"
              v-for="(seleccionado, index) in seleccionados"
              :key="index"
            >
              <div class="row">
                <div class="col-3 text-center">
                  <img
                    v-if="esXDistrito"
                    :src="seleccionado.candidatura_logo"
                    style="width:50px; height:50px"
                    class="mb-2 img-fluid"
                    alt=""
                  />
                  <img
                    v-else
                    :src="seleccionado.partido_logo"
                    style="width:50px; height:50px"
                    class="mb-2 img-fluid"
                    alt=""
                  />
                  <p v-if="esXDistrito" class="font-weight-bold mb-0" style="font-size:12px">
                    {{ seleccionado.candidatura_nombre }}
                  </p>
                  <p v-else class="font-weight-bold mb-0" style="font-size:12px">
                    {{ seleccionado.partido_nombre }}
                  </p>
                </div>
                <div class="col-7" style="border:1px solid #dee2e6">
                  <div class="row partidos">
                        <div class="float-left mb-2">
                          <img
                            v-if="esXDistrito"
                            :src="seleccionado.candidatura_logo"
                            style="width:40px; height:40px"
                          />
                          <img
                            v-else
                            :src="seleccionado.partido_logo"
                            style="width:40px; height:40px"
                          />
                        </div>
                  </div>
                  <div class="row grilla border p-2">
                    <div class="detalles">
                      <!--<avatar :colores="colores" v-if="!esPartidos || esCandidato" />-->
                      <div class="grilla">
                        <div class="total-votos col-mb-2">
                          <p class="mb-0">
                            Total de votos <br />

                            <b v-if="esXDistrito" class="texto-votos">
                              {{ seleccionado.candidatura_total_votos_numero }}
                            </b>
                            <b v-else class="texto-votos">
                              {{ seleccionado.partido_total_votos_entidad_numero }}
                            </b>

                            <b v-if="esXDistrito" class="texto-porcentaje">
                               {{ seleccionado.candidatura_total_votos_porcentaje }}%
                            </b>
                            <b v-else class="texto-porcentaje">
                               {{ seleccionado.partido_total_votos_entidad_porcentaje }}%
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <b-progress class="row"
                    v-if="esXDistrito"
                    :value="seleccionado.candidatura_total_votos_porcentaje"
                    max="100"
                    />
                    <b-progress class="row"
                    v-else
                    :value="seleccionado.partido_total_votos_entidad_porcentaje"
                    max="100"
                    />
                </div>
                <div class="col-2 align-self-center">
                    <div class="borrar mt-3">
                      <button @click="seleccionado.seleccionado = false">
                        <font-awesome-icon icon="trash-alt" class="fa-2x" />
                      </button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-12 col-md-3">
          <b-btn
            variant="primary"
            class="mx-auto d-block"
            @click="seen = !seen"
            v-if="seleccionados.length < 3"
          >
            Agregar
          </b-btn>
        </div>
      </div>
      <div class="opciones" v-if="!seen && seleccionados.length < 3">
        <p class="leyenda">
          Puedes agregar hasta tres opciones con el botón
          <font-awesome-icon icon="plus" class="fa-1x" />
        </p>
        <div class="grilla-seleccionar">
          <div
            class="elemento"
            v-for="(candidato, index) in noSeleccionados"
            :key="index"
          >
            <div class="avatar">
              <img
                :src="logo(candidato)"
                alt=""
                style="width:50px;height:50px"
              />
            </div>
            <div class="detalle">
              <div>
                <span>{{ partidoNombre(candidato) }}</span>
              </div>
            </div>
            <div class="boton">
              <button @click="candidato.seleccionado = true">
                <font-awesome-icon icon="plus" class="fa-2x" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import obtenerColoresDeAvatar from '@/utils/obtenerColoresDeAvatar';

export default {
  name: 'DetalleEleccionPartidosPoliticos',
  data() {
    return {
      seen: true,
    };
  },
  props: {
    resumen: {
      type: Object,
      default: () => {},
      required: true,
    },
    esXDistrito: {
      type: Boolean,
      default: () => false,
    },
    votos: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  computed: {
    seleccionados() {
      if (this.$router.currentRoute.name === 'VotosPorPartidoPolitico' && this.votos.partidos) {
        return this.votos.partidos.filter((partido) => partido.seleccionado);
      }
      if (this.$router.currentRoute.name === 'DetalleDelDistritoVPP' && this.votos.partidos) {
        return this.votos.partidos.filter((partido) => partido.seleccionado);
      }
      return [];
    },
    noSeleccionados() {
      if (this.$router.currentRoute.name === 'VotosPorPartidoPolitico' && this.votos.partidos) {
        return this.votos.partidos.filter((partido) => !partido.seleccionado);
      }
      if (this.$router.currentRoute.name === 'DetalleDelDistritoVPP' && this.votos.partidos) {
        return this.votos.partidos.filter((partido) => !partido.seleccionado);
      }
      return [];
    },
    colores() {
      return obtenerColoresDeAvatar(this);
    },
  },
  watch: {
    seleccionados: {
      handler(newVal) {
        if (newVal.length === 0) {
          this.$bvModal.hide('DetalleEleccionModal');
        }
      },
    },
  },
  methods: {
    cerrarModal() {
      this.$bvModal.hide('DetalleEleccionModal');
    },
    logo(candidato) {
      return candidato.partido_logo ?? candidato.candidatura_logo;
    },
    partidoNombre(candidato) {
      return candidato.partido_nombre ?? candidato.candidatura_nombre;
    },
  },
};
</script>

<style lang="scss" scoped>
.resumen {
  max-width: 300px;
  margin: 0 auto;

  .total {
    font-weight: bold;
    font-size: 60px;
    margin-bottom: 0;
  }

  .porcentaje {
    font-weight: 500;
    text-align: right;
  }
}

.detalle-card {
  .grilla {
    display: grid;
    grid-template-columns: 1fr 50px;

    .detalles {
      svg {
        max-width: 75px;
      }
    }

    .borrar {
      button {
        background-color: transparent;
        border-color: transparent;
      }
    }
  }
}

.opciones {
  margin: 100 50 50 50;
}

.leyenda {
  font-style: bold;
  margin-top: 100px;
}

.imagenPartido {
  width: 20px;
  height: 20px;
}

.tdAvatar {
  width: 50px;
}

.texto-votos{
  font-size: 20px;
  font-weight: bold;
  margin: 10px;
}

.texto-porcentaje{
  font-size: 14px;
  font-weight: normal;
}

.hide {
  visibility: hidden !important;
}

.grilla-seleccionar {
  display: grid;
  grid-template-columns: 1fr;

  .elemento {
    display: grid;
    grid-template-columns: 20% 1fr 15%;
    align-items: center;
    padding: 1rem;

    @media(min-width: 992px) {
      &:nth-child(1) {
        background: rgba(0,0,0,.05);
      }
      &:nth-child(2) {
        background: rgba(0,0,0,.05);
      }
      &:nth-child(5) {
        background: rgba(0,0,0,.05);
      }
      &:nth-child(6) {
        background: rgba(0,0,0,.05);
      }
      &:nth-child(9) {
        background: rgba(0,0,0,.05);
      }
      &:nth-child(10) {
        background: rgba(0,0,0,.05);
      }
      &:nth-child(13) {
        background: rgba(0,0,0,.05);
      }
      &:nth-child(14) {
        background: rgba(0,0,0,.05);
      }
    }
  }

  @media(min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
}

.boton {
    button {
      background-color: #34344e;
      border: none;
      padding: 5px 10px;
      border-radius: 50%;
      color: #FFF;
    }
  }
</style>
