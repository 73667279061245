<template>
  <div class="col-12 col-md-6 mb-4">
    <!--<div class="resumen-voto-candidatura" :class="{ 'ganador': partido.ganador }">-->
      <div class="resumen-voto-candidatura">
      <div class="grilla-datos-candidatura">
        <div class="nombre">
          <img v-if="esXDistrito" :src="partido.candidatura_logo" :alt="partido.candidatura_nombre">
          <img v-else :src="partido.partido_logo" :alt="partido.partido_nombre">
        </div>
        <div class="seleccion">
          <b-form-checkbox class="float-right" v-model="partido.seleccionado"/>
          <!--<input type="checkbox" class="float-right" v-model="partido.seleccionado">-->
        </div>
      </div>
      <div class="grilla-votos mb-2">
        <div class="total text-left">
          <p class="mb-0 font-weight-bold">
            Total de votos <br />
            <span v-if="esXDistrito">
              {{ partido.candidatura_total_votos_numero}}
            </span>
            <span v-else>
              {{ partido.partido_total_votos_general_numero}}
            </span>
          </p>
        </div>
        <div class="porcentaje text-right">
          <p class="mb-0 font-weight-bold">
            <span v-if="esXDistrito">
              {{ partido.candidatura_total_votos_porcentaje }}%
            </span>
             <span v-else>
              {{ partido.partido_total_votos_general_porcentaje }}%
            </span>
          </p>
        </div>
      </div>
      <div class="grilla-distribucion-votos" v-if="!esDetalle && this.$route.params.eleccion === 'G'">
        <div class="entidad">
          <p class="font-weight-bold">
            Votos
          </p>
          <p class="mb-0">
            En la Entidad <br/>
          </p>
          <p class="mb-0 text-right">
            <span class="mr-3 font-weight-bold small">{{ partido.partido_total_votos_entidad_numero }}</span>
            <span class="small">{{ partido.partido_total_votos_entidad_porcentaje }}%</span>
          </p>
        </div>
        <div class="exranjero">
          <p class="mb-0">
            En en Extranjero <br/>
          </p>
          <p class="mb-0 text-right">
            <span class="mr-3 font-weight-bold small">{{ partido.partido_total_votos_extranjero_numero }}</span>
            <span class="small">{{ partido.partido_total_votos_extranjero_porcentaje }}%</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import obtenerColoresDeAvatar from '@/utils/obtenerColoresDeAvatar'

export default {
  name: 'VotoPorPartido',
  props: {
    partido: {
      type: Object,
      default: () => {}
    },
    esDetalle: {
      type: Boolean,
      default: () => false
    },
    esXDistrito:{
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    colores () {
      return obtenerColoresDeAvatar(this)
    },
    eleccion () {
      this.$route.params.eleccion
    }
  }
}
</script>

<style lang="scss" scoped>
.ganador {
  border: 3px solid #d2127e;
  .grilla-votos {
    p > span {
      color: #d2127e;
    }
  }
}

.resumen-voto-candidatura {
  background-color: #f5f5f5;
  padding: 10px;

  .grilla-datos-candidatura {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    align-items: center;
    margin-bottom: 15px;

    .foto {
      img {
        max-width: 50px;
        display: block;
        margin: 0 auto;
      }
    }

    .nombre {
      font-size: 16px;
      img {
        max-width: 30px;
      }
    }
  }

  .grilla-votos, .grilla-distribucion-votos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
    gap: 30px;
  }

  .grilla-distribucion-votos {
    background-color: #d3d3ec;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: -10px;
    padding: 10px;
  }
}
</style>
